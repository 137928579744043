import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
  inject,
} from '@angular/core';
import { DashboardParsed } from '@/app/core/models/dashboard.model';
import Chart from 'chart.js/auto';
import { getOptions } from '@/app/features/home/dashboard-page/dashboard/demurrage/demurrage.utils';
import { Subscription, combineLatest, lastValueFrom, map } from 'rxjs';
import { UserService } from '@/app/core/services/user.service';
import { AuthService } from '@/app/core/services/auth.service';
import { ReportFormat, ReportTypes } from '@/app/core/models/report.model';
import { ReportService } from '@/app/core/services/report.service';
import { environment } from '@/environments/environment';
import { DashboardPageService } from '../../dashboard-page.service';

@Component({
  selector: 'app-demurrage',
  standalone: true,
  imports: [],
  templateUrl: './demurrage.component.html',
  styleUrl: './demurrage.component.css',
  providers: [ReportService],
})
export class DemurrageComponent implements AfterViewInit, OnDestroy {
  @Input() dashboard!: DashboardParsed;
  @ViewChild('demurrageAll') chartAll!: ElementRef;
  @ViewChild('demurrageOrig') chartOrig!: ElementRef;
  @ViewChild('demurrageDest') chartDest!: ElementRef;

  private authService = inject(AuthService);
  private userService = inject(UserService);
  private reportService = inject(ReportService);
  public username$ = this.authService.getCurrentUsername();
  public selectedFilter$ = this.userService.getSelectedFilter();
  public dashboardPageService = inject(DashboardPageService);
  reportSubscription$!: Subscription;

  getPercentage(overfree: number, total: number) {
    if (total === 0) {
      return 0;
    }
    return (overfree / total) * 100;
  }

  ngAfterViewInit() {
    const allOverFree = Number(this.dashboard.DEMALLOVERFREE ?? 0);
    const allTotal = Number(this.dashboard.DEMALLTOTAL ?? 0);
    const origOverFree = Number(this.dashboard.DEMORIGOVERFREE ?? 0);
    const origTotal = Number(this.dashboard.DEMORIGTOTAL ?? 0);
    const destOverFree = Number(this.dashboard.DEMDESTOVERFREE ?? 0);
    const destTotal = Number(this.dashboard.DEMDESTTOTAL ?? 0);

    new Chart(
      this.chartAll.nativeElement,
      getOptions(this.getPercentage(allOverFree, allTotal)),
    );
    new Chart(
      this.chartOrig.nativeElement,
      getOptions(this.getPercentage(origOverFree, origTotal)),
    );
    new Chart(
      this.chartDest.nativeElement,
      getOptions(this.getPercentage(destOverFree, destTotal)),
    );
  }

  handleReport(reportId: string) {
    this.dashboardPageService.isLoading = true;

    const params$ = combineLatest([this.username$, this.selectedFilter$]).pipe(
      map(([username, filter]) => ({
        username,
        filter,
      })),
    );

    this.reportSubscription$ = params$.subscribe(
      async ({ username, filter }) => {
        const selectedFilter = filter.FILTERS_ID!.toString();
        const selectedFormat = ReportFormat.PDF;

        const resultReportId = await lastValueFrom(
          this.reportService.generateReport(
            ReportTypes.CANNED,
            selectedFormat,
            username,
            reportId,
            selectedFilter,
            [],
          ),
        );

        if (environment.mocks) {
          this.downloadMockReport(
            resultReportId,
            selectedFilter,
            selectedFormat,
          );
        } else {
          this.openReport(resultReportId);
        }

        this.dashboardPageService.isLoading = false;
      },
    );
  }

  openReport(report: string) {
    const token = this.authService.getToken();
    const reportURL = `${environment.reportViewUrl}/${report}?access_token=${token}`;
    window.open(reportURL, '_self');
  }
  downloadMockReport(reportId: string, filterId: string, format: string) {
    const link = document.createElement('a');
    link.download = `filename-${reportId} [${filterId}].${format.toLowerCase()}`;
    link.href = 'mocks';
    link.click();
  }

  ngOnDestroy() {
    if (this.reportSubscription$) {
      this.reportSubscription$.unsubscribe();
    }
  }
}
