<body id="dashboard" class="modal-open image-background">
  <div class="device-xs visible-xs"></div>
  <ng-container
    *ngIf="{
      dashboard: dashboardPageService.dashboard$ | async,
      user: dashboardPageService.user$ | async,
      selectedFilter: dashboardPageService.selectedFilter$ | async,
      preferences: dashboardPageService.preferences$ | async,
      messages: dashboardPageService.messages$ | async
    } as data"
  >
    @if (data.dashboard && !dashboardPageService.isLoading && data.messages) {
      <div id="wrapper" class="page-container">
        <app-header></app-header>
        <app-dashboard
          [dashboard]="data.dashboard"
          [user]="data.user!"
          [selectedFilter]="data.selectedFilter!"
          [preferences]="data.preferences!"
          [messages]="data.messages"
        ></app-dashboard>
        <app-footer></app-footer>
      </div>
    } @else {
      <div id="ajaxLoader" style="display: block">
        <div class="loadingGif"></div>
        <p class="message">Loading Dashboard Data...</p>
      </div>
    }
  </ng-container>
</body>
