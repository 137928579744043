import { ChartConfiguration } from 'chart.js/auto';

export const getOptions = (value: number) =>
  ({
    type: 'doughnut',
    options: {
      rotation: -90,
      circumference: 180,
      borderWidth: 0,
      responsive: true,
      maintainAspectRatio: false,
      cutout: '60%',
      legend: { display: false },
      plugins: { tooltip: { enabled: false } },
    },
    data: {
      datasets: [
        {
          data: [value, 100 - value],
          backgroundColor: ['rgba(255, 255, 255)', 'rgba(255, 255, 255, 0.5)'],
        },
      ],
    },
  }) as ChartConfiguration;
