import { Component, inject, OnInit } from '@angular/core';
import { AsyncPipe, CommonModule, JsonPipe } from '@angular/common';
import { HeaderComponent } from '@/app/core/layout/header/header.component';
import { SidenavComponent } from '@/app/core/layout/sidenav/sidenav.component';
import { FooterComponent } from '@/app/core/layout/footer/footer.component';
import { BannerComponent } from '@/app/core/layout/banner/banner.component';
import { UserService } from '@/app/core/services/user.service';

import { BargeModalComponent } from './dashboard/barge-modal/barge-modal.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardPageService } from './dashboard-page.service';

@Component({
  selector: 'app-dashboard-page',
  standalone: true,
  imports: [
    BargeModalComponent,
    HeaderComponent,
    SidenavComponent,
    FooterComponent,
    BannerComponent,
    AsyncPipe,
    JsonPipe,
    DashboardComponent,
    CommonModule,
  ],
  templateUrl: './dashboard-page.component.html',
})
export class DashboardPageComponent implements OnInit {
  private userService = inject(UserService);
  dashboardPageService = inject(DashboardPageService);

  ngOnInit() {
    this.dashboardPageService.user$ = this.userService.getCurrentUser();
    this.dashboardPageService.dashboard$ =
      this.dashboardPageService.getDashboardData();
    this.dashboardPageService.selectedFilter$ =
      this.userService.getSelectedFilter();
    this.dashboardPageService.preferences$ =
      this.dashboardPageService.getPreferences();
    this.dashboardPageService.messages$ =
      this.dashboardPageService.getAnnouncements();
  }
}
