<div style="display: none" class="banner">
  <!-- <div class="banner"> -->
  <div class="banner-title"></div>
  <div class="banner-content">
    Towline scheduled maintenance outage from 4/16/2022 at 11 a.m. until
    4/17/2022 at ~ 7:00 a.m. (central time). The website will not reflect
    current positions until the service has been restored. Scheduled email
    reports will not be delivered during this time. For current positions and
    updates, please contact your logistics team at 1-800-298-8200.
    <!--<div class="text-center">
        <a class="btn btn-primary duty" href="./dutyList.pdf" target="_blank" role="button">Contact List</a>
    </div>-->
    <br />
    <br />
    <div class="text-center">
      <a
        class="btn btn-primary"
        href="mailto:towlinecustomer.support@ingrambarge.com"
        >Towline Support</a
      >
    </div>
  </div>
  <!--<div class="banner-note">
            (Note:  Please clear your browser’s cache.)
  </div>-->
</div>
