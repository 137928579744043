<div class="demurrage-parent">
  <div
    id="demAll"
    class="col-lg-2 col-md-2 col-sm-2 col-xs-4 demurrage"
    data-reportid="106"
  >
    <div class="tile single">
      <div id="chartAll" class="canvas" (click)="handleReport('106')">
        <label class="canvas-caption"> Demurrage All </label>
        <div class="inner-canvas">
          <canvas #demurrageAll></canvas>
          <div class="donut-range">
            <span>0</span>
            <span>{{ this.dashboard.DEMALLTOTAL }}</span>
          </div>
          <div class="donut-inner">
            <h5>{{ this.dashboard.DEMALLOVERFREE }}</h5>
            <span>Billable</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    id="demOrigin"
    class="col-lg-2 col-md-2 col-sm-2 col-xs-4 demurrage"
    data-reportid="102"
  >
    <div class="tile single">
      <div
        id="chartOrig"
        class="canvas"
        (click)="handleReport('102')"
        style="padding-top: 5px"
      >
        <label class="canvas-caption"> Demurrage Orig </label>
        <div class="inner-canvas">
          <canvas #demurrageOrig></canvas>
          <div class="donut-range">
            <span>0</span>
            <span>{{ this.dashboard.DEMORIGTOTAL }}</span>
          </div>
          <div class="donut-inner">
            <h5>{{ this.dashboard.DEMORIGOVERFREE }}</h5>
            <span>Billable</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    id="demDest"
    class="col-lg-2 col-md-2 col-sm-2 col-xs-4 demurrage"
    data-reportid="104"
  >
    <div class="tile single">
      <div id="chartDest" class="canvas" (click)="handleReport('104')">
        <label class="canvas-caption"> Demurrage Dest </label>
        <div class="inner-canvas">
          <canvas #demurrageDest></canvas>
          <div class="donut-range">
            <span>0</span>
            <span>{{ this.dashboard.DEMDESTTOTAL }}</span>
          </div>
          <div class="donut-inner">
            <h5>{{ this.dashboard.DEMDESTOVERFREE }}</h5>
            <span>Billable</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
