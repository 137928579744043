<div
  class="modal fade"
  id="etaModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  style="display: block; top: 50%; opacity: 1; bottom: 50%"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header modal-header-red">
        <button
          type="button"
          class="close white-font"
          style="
            font-weight: bold;
            outline: none;
            border: none;
            font-size: 30px;
          "
          data-dismiss="modal"
          aria-hidden="true"
          (click)="closeDialog()"
        >
          ×
        </button>
        <h4 class="modal-title white-font" id="myModalLabel">
          Adjust ETA Threshold
        </h4>
      </div>
      <div class="ETA button-control">
        <div>
          <button
            data-dashtype=""
            data-filterid=""
            class="btn border-button border-button-fill btn-minus"
            type="button"
            (click)="decreaseEta()"
          >
            <span class="glyphicon glyphicon-minus"></span>
          </button>
          <input
            id="EtaThreshold"
            type="number"
            value="{{ preferences.PREFERENCE_VALUE }}"
            (blur)="changeEta($event)"
          />
          <button
            data-dashtype=""
            data-filterid=""
            class="btn border-button border-button-fill btn-plus"
            (click)="incrementEta()"
          >
            <span class="glyphicon glyphicon-plus"></span>
          </button>
        </div>
        <div>hours</div>
      </div>
      <!-- Modal Footer -->
      <div class="modal-footer modal-header-red">
        <button
          data-dismiss="modal"
          type="button"
          class="btn cancel-button pull-right"
          (click)="closeDialog()"
        >
          Cancel
        </button>
        <button
          data-dismiss="modal"
          id="btnUpdateETA"
          type="button"
          class="btn border-button border-button-fill pull-right"
          (click)="savePreferences()"
        >
          Update
        </button>
      </div>
    </div>
  </div>
</div>
