import { Component, inject } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';

import { Preferences } from '../../../../../core/models/preferences.model';

@Component({
  selector: 'app-eta-modal',
  standalone: true,
  imports: [],
  templateUrl: './eta-modal.component.html',
})
export class EtaModalComponent {
  private dialogRef = inject(DialogRef);
  preferences: Preferences = inject(DIALOG_DATA);

  closeDialog() {
    this.dialogRef.close();
  }

  incrementEta() {
    this.preferences.PREFERENCE_VALUE = (
      Number(this.preferences.PREFERENCE_VALUE) + 1
    ).toString();
  }
  changeEta(event: Event) {
    this.preferences.PREFERENCE_VALUE = parseInt(
      (event.target as HTMLInputElement).value,
      10,
    ).toString();
  }
  decreaseEta() {
    this.preferences.PREFERENCE_VALUE = (
      Number(this.preferences.PREFERENCE_VALUE) - 1
    ).toString();
  }

  savePreferences() {
    this.dialogRef.close(this.preferences);
  }
}
