import { Component, inject, Input } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { lastValueFrom } from 'rxjs';
import { Dialog } from '@angular/cdk/dialog';
import { switchMap } from 'rxjs/operators';
import { DashboardParsed } from '@/app/core/models/dashboard.model';
import { UserService } from '@/app/core/services/user.service';
import { Preferences } from '@/app/core/models/preferences.model';
import { Filter, User } from '@/app/core/models/user.model';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { appRoutes } from '@/app/app.model';

import { DashboardService } from './dashboard.service';
import { DemurrageComponent } from './demurrage/demurrage.component';
import { EtaModalComponent } from './eta-modal/eta-modal.component';
import { Announcement } from '@/app/core/models/announcement.model';

const cloudAnimation = trigger('cloudAnimation', [
  state(cloudStates.NONE, style({ left: '0' })),
  state(cloudStates.LEFT, style({ left: '-60%' })),
  state(cloudStates.RIGHT, style({ left: '60%' })),
  transition(`${cloudStates.NONE} => ${cloudStates.RIGHT}`, [
    animate('10000ms'),
  ]),
  transition(`${cloudStates.LEFT} => ${cloudStates.NONE}`, [
    animate('10000ms'),
  ]),
]);

const enum cloudStates {
  NONE = 'none',
  LEFT = 'left',
  RIGHT = 'right',
}

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [AsyncPipe, DemurrageComponent],
  templateUrl: './dashboard.component.html',
  animations: [cloudAnimation],
})
export class DashboardComponent {
  protected readonly Number = Number;
  public readonly appRoutes = appRoutes;
  private dialog = inject(Dialog);
  dashboardService = inject(DashboardService);
  userService = inject(UserService);
  @Input() dashboard!: DashboardParsed;
  @Input() user!: User;
  @Input() selectedFilter!: Filter;
  @Input() preferences!: Preferences;
  @Input() messages: Announcement[] = [];
  hovered = '';
  hasBargeReportsAccess$ = this.userService.getBargeReportsAccess();
  state: cloudStates = cloudStates.NONE;
  finished = false;

  getMessagesCount() {
    return this.messages.filter((message) => !message.Read).length;
  }
  onEnd() {
    if (this.finished) {
      return;
    }
    switch (this.state) {
      case cloudStates.NONE:
        this.state = cloudStates.RIGHT;
        break;
      case cloudStates.RIGHT:
        this.state = cloudStates.LEFT;
        break;
      case cloudStates.LEFT:
        this.state = cloudStates.NONE;
        this.finished = true;
        break;
    }
  }
  openETAModal() {
    const dialogRef = this.dialog.open(EtaModalComponent, {
      data: this.preferences,
    });
    return lastValueFrom(
      dialogRef.closed.pipe(
        switchMap((preferences) =>
          this.userService.updatePreferences(preferences as Preferences),
        ),
      ),
    );
  }
}
