<div id="content" class="clearfix">
  <div id="ty"></div>
  <div id="DashboardTiles">
    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
      <div class="tile double">
        <div
          class="display col-lg-12 col-md-12 col-sm-12 col-xs-4 item"
          data-order="0"
          (mouseenter)="hovered = 'barges'"
          (mouseleave)="hovered = ''"
        >
          <div
            class="noFocus"
            [class]="hovered === 'barges' ? 'hide' : 'block'"
            (click)="
              Number(dashboard.ALLBARGES) > 0 &&
                dashboardService.redirectWithParams(appRoutes.BARGE_LIST, 'ALL')
            "
          >
            <div data-filterid="" class="title">All Barges</div>
            <div class="data">
              {{ dashboard.ALLBARGES }}
            </div>
          </div>
          <div class="hasFocus btn-two">
            <div class="focusInfo">
              <span data-filterid="" class="title">All Barges</span>:
              <span class="data">{{ dashboard.ALLBARGES }}</span>
            </div>
            <button
              type="button"
              class="btn border-button border-button-fill mapBarges"
              [disabled]="Number(dashboard.ALLBARGES) === 0"
              (click)="
                dashboardService.redirectWithParams(appRoutes.MAP, 'ALL')
              "
            >
              <span class="glyphicon glyphicon-map-marker"></span>
            </button>
            <button
              type="button"
              class="btn border-button border-button-fill listBarges hasOptions"
              [disabled]="Number(dashboard.ALLBARGES) === 0"
              (click)="
                dashboardService.redirectWithParams(appRoutes.BARGE_LIST, 'ALL')
              "
            >
              <span class="glyphicon glyphicon-th-list"></span>
            </button>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-4">
          <div class="boatIcon"></div>
        </div>
        <div
          class="col-lg-12 col-md-12 col-sm-12 col-xs-4 container-show-items"
          data-order="9"
          (mouseenter)="hovered = 'barge-eta'"
          (mouseleave)="hovered = ''"
        >
          <div
            class="noFocus first-show-item"
            [class]="hovered === 'barge-eta' ? 'hide' : 'block'"
          >
            <div data-filterid="" class="title" id="ETA">
              ETA &lt;
              <span class="hours">
                {{ preferences.PREFERENCE_VALUE || "0" }}
              </span>
              hrs
            </div>
            <div class="data" id="ETAD">
              {{ dashboard.ETA }}
            </div>
          </div>
          <div class="hasFocus btn-three second-show-item">
            <div class="focusInfo">
              <span data-filterid="" class="title">
                ETA &lt;
                <span class="hours">
                  {{ preferences.PREFERENCE_VALUE || "0" }}
                </span>
                hrs
              </span>
              :
              <span class="data">0</span>
            </div>
            <button
              type="button"
              class="btn border-button border-button-fill changeETA"
              (click)="openETAModal()"
            >
              <span class="glyphicon glyphicon-cog"></span>
            </button>
            <button
              type="button"
              class="btn border-button border-button-fill mapBarges"
              [disabled]="Number(dashboard.ETA) === 0"
              (click)="
                dashboardService.redirectWithParams(appRoutes.MAP, 'ETA')
              "
            >
              <span class="glyphicon glyphicon-map-marker"></span>
            </button>
            <button
              type="button"
              class="btn border-button border-button-fill listBarges hasOptions"
              [disabled]="Number(dashboard.ETA) === 0"
              (click)="
                dashboardService.redirectWithParams(appRoutes.BARGE_LIST, 'ETA')
              "
            >
              <span class="glyphicon glyphicon-th-list"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-6">
      <div
        data-order="1"
        class="tile single item"
        (mouseenter)="hovered = 'loaded'"
        (mouseleave)="hovered = ''"
      >
        <div
          role="button"
          class="noFocus"
          [class]="hovered === 'loaded' ? 'hide' : 'block'"
          (click)="
            Number(dashboard.LOADED) > 0 &&
              dashboardService.redirectWithParams(
                appRoutes.BARGE_LIST,
                'LOADED'
              )
          "
        >
          <div class="title">Loaded</div>
          <div class="data">{{ dashboard.LOADED }}</div>
        </div>
        <div class="hasFocus btn-two">
          <div class="focusInfo">
            <span data-filterid="" class="title">Loaded</span>:
            <span class="data">{{ dashboard.LOADED }}</span>
          </div>
          <button
            type="button"
            class="btn border-button border-button-fill mapBarges"
            [disabled]="Number(dashboard.LOADED) === 0"
            (click)="
              dashboardService.redirectWithParams(appRoutes.MAP, 'LOADED')
            "
          >
            <span class="glyphicon glyphicon-map-marker"></span>
          </button>
          <button
            type="button"
            class="btn border-button border-button-fill listBarges hasOptions"
            [disabled]="Number(dashboard.LOADED) <= 0"
            (click)="
              dashboardService.redirectWithParams(
                appRoutes.BARGE_LIST,
                'LOADED'
              )
            "
          >
            <span class="glyphicon glyphicon-th-list"></span>
          </button>
        </div>
      </div>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-6">
      <div
        data-order="2"
        class="tile single item"
        (mouseenter)="hovered = 'empty'"
        (mouseleave)="hovered = ''"
      >
        <div
          class="noFocus"
          (click)="
            Number(dashboard.EMPTY) > 0 &&
              dashboardService.redirectWithParams(appRoutes.BARGE_LIST, 'EMPTY')
          "
          [class]="hovered === 'empty' ? 'hide' : 'block'"
        >
          <div data-filterid="" class="title">Empty</div>
          <div class="data">{{ dashboard.EMPTY }}</div>
        </div>
        <div class="hasFocus btn-two">
          <div class="focusInfo">
            <span data-filterid="" class="title">Empty</span>:
            <span class="data">{{ dashboard.EMPTY }}</span>
          </div>
          <button
            type="button"
            class="btn border-button border-button-fill mapBarges"
            [disabled]="Number(dashboard.EMPTY) === 0"
            (click)="
              dashboardService.redirectWithParams(appRoutes.MAP, 'EMPTY')
            "
          >
            <span class="glyphicon glyphicon-map-marker"></span>
          </button>
          <button
            type="button"
            class="btn border-button border-button-fill listBarges hasOptions"
            [disabled]="Number(dashboard.EMPTY) === 0"
            (click)="
              dashboardService.redirectWithParams(appRoutes.BARGE_LIST, 'EMPTY')
            "
          >
            <span class="glyphicon glyphicon-th-list"></span>
          </button>
        </div>
      </div>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-6">
      <div
        data-order="3"
        class="tile single item"
        (mouseenter)="hovered = 'moving'"
        (mouseleave)="hovered = ''"
      >
        <div
          class="noFocus"
          (click)="
            Number(dashboard.EMPTY) > 0 &&
              dashboardService.redirectWithParams(
                appRoutes.BARGE_LIST,
                'MOVING'
              )
          "
          [class]="hovered === 'moving' ? 'hide' : 'block'"
        >
          <div data-filterid="" class="title">Moving</div>
          <div class="data">{{ dashboard.MOVING }}</div>
        </div>
        <div class="hasFocus btn-two">
          <div class="focusInfo">
            <span data-filterid="" class="title">Moving</span>:
            <span class="data">{{ dashboard.MOVING }}</span>
          </div>
          <button
            type="button"
            class="btn border-button border-button-fill mapBarges"
            [disabled]="Number(dashboard.MOVING) === 0"
            (click)="
              dashboardService.redirectWithParams(appRoutes.MAP, 'MOVING')
            "
          >
            <span class="glyphicon glyphicon-map-marker"></span>
          </button>
          <button
            type="button"
            class="btn border-button border-button-fill listBarges hasOptions"
            [disabled]="Number(dashboard.MOVING) === 0"
            (click)="
              dashboardService.redirectWithParams(
                appRoutes.BARGE_LIST,
                'MOVING'
              )
            "
          >
            <span class="glyphicon glyphicon-th-list"></span>
          </button>
        </div>
      </div>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-6 clearfix">
      <div
        data-order="4"
        class="tile single item"
        (mouseenter)="hovered = 'stanby'"
        (mouseleave)="hovered = ''"
      >
        <div
          class="noFocus"
          (click)="
            Number(dashboard.STANDBY) > 0 &&
              dashboardService.redirectWithParams(
                appRoutes.BARGE_LIST,
                'STANDBY'
              )
          "
          [class]="hovered === 'stanby' ? 'hide' : 'block'"
        >
          <div data-filterid="" class="title">Stand By</div>
          <div class="data">{{ dashboard.STANDBY }}</div>
        </div>
        <div class="hasFocus btn-two">
          <div class="focusInfo">
            <span data-filterid="" class="title">Stand By</span>:
            <span class="data">{{ dashboard.STANDBY }}</span>
          </div>
          <button
            type="button"
            class="btn border-button border-button-fill mapBarges"
            [disabled]="Number(dashboard.STANDBY) === 0"
            (click)="
              dashboardService.redirectWithParams(appRoutes.MAP, 'STANDBY')
            "
          >
            <span class="glyphicon glyphicon-map-marker"></span>
          </button>
          <button
            type="button"
            class="btn border-button border-button-fill listBarges hasOptions"
            [disabled]="Number(dashboard.STANDBY) === 0"
            (click)="
              dashboardService.redirectWithParams(
                appRoutes.BARGE_LIST,
                'STANDBY'
              )
            "
          >
            <span class="glyphicon glyphicon-th-list"></span>
          </button>
        </div>
      </div>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-6">
      <div
        role="button"
        class="alt tile single MessageContainers"
        id="MessageContainers"
        (click)="dashboardService.redirectToRoute(appRoutes.MESSAGES)"
      >
        <div id="MessageTile">
          <div class="data"><span class="imgIcon"></span></div>
          <div data-filterid="" class="title">
            {{ getMessagesCount() }} Messages
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-5 col-md-5 col-sm-5 col-xs-6">
      <div
        data-order="5"
        class="tile single item"
        (mouseenter)="hovered = 'placedorig'"
        (mouseleave)="hovered = ''"
      >
        <div
          class="noFocus"
          (click)="
            Number(dashboard.PLACEDORIG) > 0 &&
              dashboardService.redirectWithParams(appRoutes.BARGE_LIST, 'ORIG')
          "
          [class]="hovered === 'placedorig' ? 'hide' : 'block'"
        >
          <div data-filterid="" class="title">Placed Origin</div>
          <div class="data">
            {{ dashboard.PLACEDORIG }}
          </div>
        </div>
        <div class="hasFocus btn-two">
          <div class="focusInfo">
            <span data-filterid="" class="title">Placed Origin</span>:
            <span class="data">
              {{ dashboard.PLACEDORIG }}
            </span>
          </div>
          <button
            type="button"
            class="btn border-button border-button-fill mapBarges"
            [disabled]="Number(dashboard.PLACEDORIG) === 0"
            (click)="dashboardService.redirectWithParams(appRoutes.MAP, 'ORIG')"
          >
            <span class="glyphicon glyphicon-map-marker"></span>
          </button>
          <button
            type="button"
            class="btn border-button border-button-fill listBarges"
            [disabled]="Number(dashboard.PLACEDORIG) === 0"
            (click)="
              dashboardService.redirectWithParams(appRoutes.BARGE_LIST, 'ORIG')
            "
          >
            <span class="glyphicon glyphicon-th-list"></span>
          </button>
        </div>
      </div>
    </div>
    <div class="col-lg-5 col-md-5 col-sm-5 col-xs-6 clearfix">
      <div
        data-order="6"
        class="tile single item"
        (mouseenter)="hovered = 'placeddest'"
        (mouseleave)="hovered = ''"
      >
        <div
          class="noFocus"
          (click)="
            Number(dashboard.PLACEDDEST) > 0 &&
              dashboardService.redirectWithParams(appRoutes.BARGE_LIST, 'DEST')
          "
          [class]="hovered === 'placeddest' ? 'hide' : 'block'"
        >
          <div data-filterid="" class="title">Placed Dest</div>
          <div class="data">
            {{ dashboard.PLACEDDEST }}
          </div>
        </div>
        <div class="hasFocus btn-two">
          <div class="focusInfo">
            <span data-filterid="" class="title">Placed Dest</span>:
            <span class="data">
              {{ dashboard.PLACEDDEST }}
            </span>
          </div>
          <button
            type="button"
            class="btn border-button border-button-fill mapBarges"
            [disabled]="Number(dashboard.PLACEDDEST) === 0"
            (click)="dashboardService.redirectWithParams(appRoutes.MAP, 'DEST')"
          >
            <span class="glyphicon glyphicon-map-marker"></span>
          </button>
          <button
            type="button"
            class="btn border-button border-button-fill listBarges"
            [disabled]="Number(dashboard.PLACEDDEST) === 0"
            (click)="
              dashboardService.redirectWithParams(appRoutes.BARGE_LIST, 'DEST')
            "
          >
            <span class="glyphicon glyphicon-th-list"></span>
          </button>
        </div>
      </div>
    </div>
    <div
      class="col-lg-2 col-md-2 col-sm-2 col-xs-6"
      (click)="
        dashboardService.redirectToRoute(
          appRoutes.CONDITIONS,
          'riverconditions'
        )
      "
    >
      <div class="tile single">
        <div data-filterid="" class="title">Conditions</div>
        <div class="data" style="height: 41px">
          <span
            class="glyphicon glyphicon-cloud"
            style="left: 0"
            [@cloudAnimation]="state"
            (@cloudAnimation.done)="onEnd()"
          ></span>
        </div>
      </div>
    </div>
    <div
      class="col-lg-2 col-md-2 col-sm-2 col-xs-6"
      (click)="dashboardService.redirectToRoute(appRoutes.BOAT_LIST)"
      style="cursor: pointer"
    >
      <div class="tile single">
        <div data-filterid="" class="title">Boat Positions</div>
        <div class="data">
          <span class="glyphicon glyphicon-map-marker"></span>
        </div>
      </div>
    </div>
    <div
      class="col-lg-2 col-md-2 col-sm-2 col-xs-6"
      (click)="
        dashboardService.redirectToRoute(appRoutes.CONDITIONS, 'lockdelays')
      "
      style="cursor: pointer"
    >
      <div class="tile single">
        <div data-filterid="" class="title">Lock Delays</div>
        <div class="data">
          <span class="glyphicon glyphicon-lock"></span>
        </div>
      </div>
    </div>
    <div
      class="col-lg-2 col-md-2 col-sm-2 col-xs-6"
      (click)="
        dashboardService.redirectToRoute(appRoutes.CONDITIONS, 'riverstages')
      "
      style="cursor: pointer"
    >
      <div class="tile single">
        <div data-filterid="" class="title">River Stages</div>
        <div class="data">
          <span class="glyphicon glyphicon-sort-by-attributes"></span>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-6">
      <div
        class="tile single filter-modal"
        id="filter-modal"
        (click)="dashboardService.openDialog()"
      >
        <div data-filterid="" class="title lblCurrentFilter">
          {{ selectedFilter.FILTER_NAME }}
        </div>
        <div class="data">
          <span
            class="glyphicon glyphicon-filter"
            style="padding-top: 10px"
          ></span>
        </div>
      </div>
    </div>
    @if (user.SEE_DEMURRAGE) {
      <app-demurrage [dashboard]="dashboard"></app-demurrage>
    }
    @if (hasBargeReportsAccess$ | async) {
      <div
        id="bargeReport"
        class="col-lg-2 col-md-2 col-sm-2 col-xs-6"
        (click)="dashboardService.redirectToRoute(appRoutes.OXY_BARGE_REPORTS)"
        style="cursor: pointer"
      >
        <div class="tile single">
          <div data-filterid="" class="title">Oxy Barge Report</div>
          <div class="data">
            <span class="glyphicon glyphicon-stats"></span>
          </div>
        </div>
      </div>
    }
  </div>
</div>
